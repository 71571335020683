<template>
    <div class="w-full h-full flex items-center justify-center bg-lightgray">

        <div class="h-full w-full bg-lightgray flex flex-col justify-center items-center shadow-shadowcard max-w-lg overflow-hidden">

            <div class="px-2 pt-2 mt-4">
                <img class="mx-auto w-auto h-16" src="/img/grupoempresarial.png">
            </div>

            <Request
                     model="Auth"
                     action="login"
                     :form="form"
                     @success="onSuccess"
                     @error="onError"
                     v-slot="{ request, loading }" class="w-full">

                <div
                     @keypress.enter="request"
                     class="mt-2 w-full py-4 rounded-lg flex flex-col justify-center items-center">

                    
                        <input type="email" v-model="form.email" class="w-10/12 mb-1 form-input py-1 border-0 text-dfont font-semibold" :class="{'border border-red': errorloguin}" placeholder="Email">
                        <input type="password" v-model="form.password" class="w-10/12 form-input py-1 border-0 text-dfont font-semibold" :class="{'border border-red': errorloguin}" :placeholder="$t('password')">
                 

                    <div class="mt-4 w-5/12 shadow">
                        <button
                                @click="request"
                                class="w-full px-4 py-1 bg-red font-medium text-white rounded-lg"
                                :class="{ 'opacity-50': loading }"
                                :disabled="loading">
                            Login
                        </button>
                    </div>

                    <div class="h-auto w-auto mt-2">
                        <router-link to="recoverypass">
                            <span class="text-red text-xs font-ligth">{{$t('forgottenPassword')}}</span>
                        </router-link>
                    </div>
                </div>
            </Request>

            <div class="h-auto py-2 bg-darkgray2 flex flex-row justify-center items-center">
                <span class="text-2xs font-thing text-gray">Powered by Zeus</span>
            </div>

        </div>


    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { routeByUser } from '@/router/helpers';
    import { Auth } from '@/api/models';
    import { actions, state } from '@/store';

    export default {
        name: 'login',
        components: {
            Request
        },
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                errorloguin: false
            };
        },
        computed: {
            user() {
                return state.user;
            },
            firstLogin() {
                return this.user.firstlogin;
            },
            role() {
                return this.user.rol.Name;
            }
        },
        methods: {
            async onSuccess(token) {

                actions.setToken(token);

                const user = await Auth.me();
                actions.setUser(user);

                const route = routeByUser(user);
                this.$router.push(route);

            },
            onError(error) {
                this.$message.error(state.messageError);
                this.errorloguin = true

            }
        },
        mounted() {
            if(state.token){
                const route = routeByUser(this.user);
                this.$router.push(route);
            }
        }
    };
</script>
